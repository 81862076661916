<template>
  <div class="EduHome">
    <EduHeader />
    <main class="containerMain">
      <NavInfo />
      <div class="containerMainRight">
        <div class="TitleReportCenter">
          <div class="TitleReportCenter-Title">近期答题报告</div>
          <div class="TitleReportCenter-main">
            <div class="TitleReportCenter-mainItem">
              <div>
                <img src="~@/assets/TrueTopic/iconc-1.png" />
                <span>1</span>
              </div>
              <div>
                <img src="~@/assets/TrueTopic/iconc-2.png" />
                <span>1</span>
              </div>
              <div>
                <img src="~@/assets/TrueTopic/iconc-3.png" />
                <span>1</span>
              </div>
            </div>
            <div class="TitleReportCenterinco">
              <div class="TitleReportCenterFont">
                做题用时：<span>60分钟</span>
              </div>
              <div class="TitleReportCenterFont">错误率：<span>10%</span></div>
              <div class="TitleReportCenterSelect">
                <div class="slect">
                  <span>问题1</span>
                  <i><img src="~@/assets/TrueTopic/select-1.png" /></i>
                </div>
                <div class="slect on">
                  <span>问题1</span>
                  <i><img src="~@/assets/TrueTopic/select-2.png" /></i>
                </div>
              </div>
              <div class="TitleReportItem1">
                <i></i>
                <div class="on"><span></span>查看解析</div>
                <div><span></span>查看解析</div>
              </div>
              <div class="TrueTopicItem">
                <div class="TrueTopicItemTitle">
                  <div class="TrueTopicItemTitleFl">
                    <i><img src="~@/assets/TrueTopic/iconshang.png" /></i>
                    <span>问答题</span>
                    <div>
                      <i><img src="~@/assets/TrueTopic/iconjiao.png" /></i
                      ><span>加入收藏夹</span>
                    </div>
                  </div>
                  <div class="TrueTopicItemTitleFr">用时间：0：02：45</div>
                </div>
                <div class="TrueTopicItemFont">
                  <div class="TrueTopicItemFontTitle">什么是贷款？</div>
                  <div class="TrueTopicItemFontInfo">
                    贷款（电子借条信用贷款 [5] ）简单通俗的理解，就是需要 利息
                    的借钱。. 贷款是 银行 或其他 金融机构
                    按一定利率和必须归还等条件出借 货币资金
                    的一种信用活动形式。. 广义的贷款指贷款、 贴现 、 透支
                    等出贷资金的总称。. 银行通过贷款的方式将所集中的货币和
                    货币资金 投放出去，可以满足社会 扩大再生产
                    对补充资金的需要，促进经济的发展，同时，银行也可以由此取得贷款
                    利息收入 ，增加银行自身的积累。
                  </div>
                </div>
                <div class="TrueTopicItemp30">
                  <div class="fl">得 0分 共2.5分</div>
                  <div class="fr"></div>
                </div>
                <div class="TrueTopicItemFont">
                  <div class="TrueTopicItemFontTitle">
                    贷款（电子借条信用贷款 [5] ）简单通俗的理解，就是需要 利息
                    的借钱。. 贷款是 银行 或其他 金融机构
                    按一定利率和必须归还等条件出借 货币资金
                  </div>
                  <div class="TrueTopicItemFontInfo">
                    <video src="" controls></video>
                    <div class="TrueTopicItemFontpl">
                      <div class="TrueTopicItemFontpl-title">错题解析视频</div>
                      <div class="TrueTopicItemFontpl-but">
                        <button @click="purchaseFn()">购买</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="TitleReportCenterSelect">
                <div class="slect">
                  <span>问题1</span>
                  <i><img src="~@/assets/TrueTopic/select-1.png" /></i>
                </div>
                <div class="slect on">
                  <span>问题1</span>
                  <i><img src="~@/assets/TrueTopic/select-2.png" /></i>
                </div>
              </div>
              <div class="TrueTopicItem">
                <div class="TrueTopicItemTitle">
                  <div class="TrueTopicItemTitleFl">
                    <i><img src="~@/assets/TrueTopic/iconshang.png" /></i>
                    <span>问答题</span>
                    <div>
                      <i><img src="~@/assets/TrueTopic/iconjiao.png" /></i
                      ><span>加入收藏夹</span>
                    </div>
                  </div>
                  <div class="TrueTopicItemTitleFr">用时间：0：02：45</div>
                </div>
                <div class="TrueTopicItemFont">
                  <div class="TrueTopicItemFontTitle">
                    1、在GDP的几大部分构成中,不包括
                  </div>
                  <div class="TrueTopicItemFontInfo">
                    <dl style="line-height: 36px">
                      <dd>
                        <label> <input type="checkbox" /> A、证券机构 </label>
                      </dd>
                      <dd>
                        <label> <input type="checkbox" /> A、证券机构 </label>
                      </dd>
                      <dd>
                        <label> <input type="checkbox" /> A、证券机构 </label>
                      </dd>
                      <dd>
                        <label> <input type="checkbox" /> A、证券机构 </label>
                      </dd>
                    </dl>
                  </div>
                </div>
                <div class="TrueTopicItemp30">
                  <div class="fl">得 0分 共2.5分</div>
                  <div class="fr">正确答案：A</div>
                </div>
                <div class="TrueTopicItemFont">
                  <div class="TrueTopicItemFontTitle">
                    贷款（电子借条信用贷款 [5] ）简单通俗的理解，就是需要 利息
                    的借钱。. 贷款是 银行 或其他 金融机构
                    按一定利率和必须归还等条件出借 货币资金
                  </div>
                  <div class="TrueTopicItemFontInfo">
                    <video src="" controls></video>
                    <div class="TrueTopicItemFontpl">
                      <div class="TrueTopicItemFontpl-title">错题解析视频</div>
                      <div class="TrueTopicItemFontpl-but">
                        <button @click="purchaseFn()">购买</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="TitleReportCenter-tItem">
            <div class="TitleReportCenter-time">
              倒计时：<span>1小时20分</span>
            </div>
          </div>
          <ul>
            <li>
              <div class="FontTitle">选择题</div>
              <div class="TitleReportCenter-Font">
                在GDP的几大部分构成中,不包括
              </div>
              <div class="TitleReportCenter-or">
                <dl>
                  <dd>
                    <div class="TitleReportCenter-orDdTitle">
                      <label>
                        <input checked name="tm4" type="checkbox" />
                        <div>
                          约翰想知道在他的自行车店里，哪种清点最合适的方法。他决定使用与过去几年相同的方法，因为他的业务基本上以同样的方式运营。
                        </div>
                      </label>
                    </div>
                    <div class="TitleReportCenter-info">
                      这是一致性的例子，而不是历史成本。
                    </div>
                  </dd>
                  <dd>
                    <div class="TitleReportCenter-orDdTitle">
                      <label>
                        <input name="tm4" type="checkbox" />
                        <div>
                          约翰想知道在他的自行车店里，哪种清点最合适的方法。他决定使用与过去几年相同的方法，因为他的业务基本上以同样的方式运营。
                        </div>
                      </label>
                    </div>
                    <div class="TitleReportCenter-info">
                      这是一致性的例子，而不是历史成本。
                    </div>
                  </dd>
                  <dd>
                    <div class="TitleReportCenter-orDdTitle">
                      <label>
                        <input name="tm4" type="checkbox" />
                        <div>
                          约翰想知道在他的自行车店里，哪种清点最合适的方法。他决定使用与过去几年相同的方法，因为他的业务基本上以同样的方式运营。
                        </div>
                      </label>
                    </div>
                    <div class="TitleReportCenter-info">
                      这是一致性的例子，而不是历史成本。
                    </div>
                  </dd>
                  <dd>
                    <div class="TitleReportCenter-orDdTitle">
                      <label>
                        <input name="tm4" type="checkbox" />
                        <div>
                          约翰想知道在他的自行车店里，哪种清点最合适的方法。他决定使用与过去几年相同的方法，因为他的业务基本上以同样的方式运营。
                        </div>
                      </label>
                    </div>
                    <div class="TitleReportCenter-info">
                      这是一致性的例子，而不是历史成本。
                    </div>
                  </dd>
                </dl>
              </div>
            </li>
            <li>
              <div class="FontTitle">名词解释题</div>
              <div class="TitleReportCenter-Font">什么是贷款？</div>
              <div class="TitleReportCenter-text">
                <textarea></textarea>
              </div>
            </li>
          </ul> -->

          <!-- <div class="TitleReportBut">
            <button class="but2" type="button">提交</button>
          </div> -->
        </div>
      </div>
      <div class="purchaseShow" v-if="purchaseShow">
        <div class="purchaseShowInfo">
          <div class="cob" @click="gbFn">
            <img src="~@/assets/icon-gb.png" />
          </div>
          <div class="purchaseShow-title">
            <span>立即登录</span> 请登录后购买课程视频
          </div>
          <div class="purchaseShow-ul">
            <ul>
              <li>
                <div class="p10px on">
                  <div class="p10px-title">
                    本节关联视频<br />超值推荐低至<span>10</span>元/视频
                  </div>
                  <div class="p10px-font"><span>80</span> 元</div>
                  <div class="p10px-f30">30个视频</div>
                  <div class="but">立即购买</div>
                </div>
              </li>
              <li>
                <div class="p10px">
                  <div class="p10px-title">
                    本节关联视频<br />超值推荐低至<span>10</span>元/视频
                  </div>
                  <div class="p10px-font"><span>80</span> 元</div>
                  <div class="p10px-f30">30个视频</div>
                  <div class="but">立即购买</div>
                </div>
              </li>
              <li>
                <div class="p10px">
                  <div class="p10px-title">
                    本节关联视频<br />超值推荐低至<span>10</span>元/视频
                  </div>
                  <div class="p10px-font"><span>80</span> 元</div>
                  <div class="p10px-f30">30个视频</div>
                  <div class="but">立即购买</div>
                </div>
              </li>
              <li>
                <div class="p10px">
                  <div class="p10px-title">
                    本节关联视频<br />超值推荐低至<span>10</span>元/视频
                  </div>
                  <div class="p10px-font"><span>80</span> 元</div>
                  <div class="p10px-f30">30个视频</div>
                  <div class="but">立即购买</div>
                </div>
              </li>
            </ul>
          </div>
          <div class="purchaseShow-zf">
            <div class="purchaseShow-zf-left">
              <div>微信/支付宝支付</div>
            </div>
            <div class="purchaseShow-zf-right">
              <div class="purchaseShow-zf-right-col">
                <img src="~@/assets/code.png" />
              </div>
              <div class="purchaseShow-zf-right-font">
                <div class="money">￥<span>80</span></div>
                <div class="money-bottom">
                  支持使用支付宝，微信支付<br />《会员服务协议》
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import EduHeader from "@/components/EduHeader";
import NavInfo from "@/components/NavInfo";
export default {
  name: "EduHome",
  data() {
    return {
      activeName: "Teaching",
      show: false,
      info: "对",
      purchaseShow: false,
    };
  },
  methods: {
    purchaseFn() {
      this.purchaseShow = true;
    },
    gbFn() {
      this.purchaseShow = false;
    },
    showBu(row) {
      this.info = row;
      this.show = false;
    },
    showFn() {
      this.show = !this.show;
    },
  },
  components: {
    EduHeader,
    NavInfo,
  },
};
</script>

<style lang="scss" scoped>
.containerMain {
  background: #fafafa;
  position: relative;
  .containerMainRight {
    padding-left: 410px;
    .TitleReportCenter {
      width: 700px;
      margin: 0px auto;
      padding: 75px 0 15px 0;
      ul {
        li {
          padding-bottom: 20px;
        }
      }
      .FontTitle {
        font-weight: bold;
        font-size: 20px;
        font-weight: bold;
        color: #173d7c;
      }
      .TitleReportCenterTitle {
        font-size: 36px;
        font-weight: 400;
        color: #060606;
        line-height: 60px;
        margin-top: 10px;
      }
      .TitleReportCenter-Title {
        font-weight: bold;
        font-size: 28px;
        font-weight: bold;
        color: #575b67;
      }
      .TitleReportCenter-main {
        border-radius: 6px 6px 6px 6px;
        border: 1px solid #707070;
        margin-top: 20px;
        .TitleReportCenter-mainItem {
          border-bottom: 1px solid #707070;
          div {
            float: left;
            width: 33.33333%;
            display: block;
            box-sizing: border-box;
            text-align: center;
            border-left: 1px solid #707070;
            line-height: 215px;
            font-size: 0;
            height: 215px;
            overflow: hidden;
            img {
              display: inline-block;
            }
            span {
              font-size: 60px;
              font-weight: bold;
              color: #7b7b7b;
              display: inline-block;
              margin-left: 15px;
              line-height: 215px;
            }
            &:first-child {
              margin-left: 0;
              border-left: 0;
            }
          }
          &::after {
            clear: both;
            display: block;
            content: "";
          }
        }
        .TitleReportCenterinco {
          padding: 25px 30px;
        }
        .TitleReportCenterFont {
          font-size: 16px;
          font-weight: 400;
          color: #636363;
          line-height: 36px;
          span {
            color: #8d0005;
          }
        }
        .TitleReportCenterSelect {
          padding: 10px 0;
          .slect {
            position: relative;
            float: left;
            height: 20px;
            background: #ffffff;
            border-radius: 10px 10px 10px 10px;
            opacity: 1;
            border: 1px solid #707070;
            padding: 0 12px;
            span {
              float: left;
              font-size: 12px;
            }
            i {
              float: left;
              margin-left: 5px;
              font-size: 0;
              margin-top: 3px;
            }
            &::after {
              clear: both;
              display: block;
              content: "";
            }
            &.on {
              height: 20px;
              background: #8d0005;
              border-radius: 10px 10px 10px 10px;
              opacity: 1;
              border: 1px solid #6d0004;
              color: #ffffff;
            }
          }
          &::after {
            clear: both;
            display: block;
            content: "";
          }
        }
        &::after {
          clear: both;
          display: block;
          content: "";
        }
      }
      .TitleReportCenter-tItem {
        margin-top: 10px;
        height: 40px;
        .TitleReportCenter-topic {
          display: block;
          height: 40px;
          background: #636363;
          border: 1px solid #707070;
          border-radius: 20px;
          line-height: 40px;
          padding: 0 25px;
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
          float: left;
        }
        .TitleReportCenter-time {
          float: right;
          line-height: 40px;
          font-size: 16px;
          span {
            color: #8d0005;
          }
        }
      }
      .page-center {
        height: 32px;
        background: url("~@/assets/TrueTopic/itembg.png") repeat-x left;
        text-align: center;
        margin-top: 30px;
        .itemPage {
          height: 30px;
          background: #ffffff;
          border: 1px solid #d9d9d9;
          opacity: 1;
          border-radius: 15px;
          display: inline-block;
          box-sizing: content-box;
          .left {
            float: left;
            background: url("~@/assets/TrueTopic/left.png") no-repeat center;

            cursor: pointer;
            padding: 0 15px;
            border-right: 1px solid #d9d9d9;
            height: 30px;
            width: 24px;
            box-sizing: content-box;
          }
          .right {
            float: right;
            background: url("~@/assets/TrueTopic/right.png") no-repeat center;
            cursor: pointer;
            padding: 0 15px;
            border-left: 1px solid #d9d9d9;
            height: 30px;
            width: 24px;
            box-sizing: content-box;
          }
          .center {
            padding: 0 70px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 30px;
            color: #575b66;
          }
          &:hover {
            border: 1px solid #8d0005;
            .left {
              border-right: 1px solid #8d0005;
            }
            .right {
              border-left: 1px solid #8d0005;
            }
            .center {
              color: #8d0005;
            }
          }
        }
      }
      .TitleReportCenter-Font {
        margin-top: 20px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #060606;
      }
      .TitleReportCenter-or {
        margin-top: 20px;
        border: 1px solid #d6d6d6;
        border-radius: 6px;
        dl {
          dd {
            .TitleReportCenter-orDdTitle {
              padding: 20px 14px;
              label {
                cursor: pointer;
                display: block;
                position: relative;
                input {
                  float: left;
                  margin-top: 6px;
                }
                div {
                  padding-left: 35px;
                  padding-right: 35px;
                  font-size: 16px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  line-height: 24px;
                  color: #060606;
                }
                i {
                  position: absolute;
                  right: 0px;
                  top: 5px;
                }
              }
            }
            .TitleReportCenter-info {
              background: #e8e8e8;

              border-radius: 0px;
              padding: 20px 20px 20px 50px;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 24px;
              color: #060606;
            }
          }
        }
      }
      .TitleReportCenter-text {
        margin-top: 20px;
        textarea {
          height: 350px;
          width: 100%;
          border: 0;
          font-size: 16px;
          padding: 5px 0;
          line-height: 24px;
        }
      }
    }
  }
}
.TitleReportBut {
  padding: 50px 0;
  text-align: center;
  button {
    height: 44px;
    font-size: 16px;
    border-radius: 8px;
    padding: 0 30px;
    margin: 0 10px;
    cursor: pointer;
    color: #333333;
    border: 0;
    &.but1 {
      background: #5a5a5d;
    }
    &.but2 {
      background: #ffcf00;
    }
  }
}

.tmItem li::after {
  clear: both;
  display: block;
  content: "";
}
.EduHome {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  footer {
    padding: 24px 40px 40px;
    font-size: 16px;
    color: $color-text-6;
    background-color: $color-background-5;
  }
}
.TitleReportCenter-item {
  margin-top: 10px;
  dl {
    dd {
      float: left;
      margin-right: 10px;
      height: 30px;
      background: #173d7c;
      border-radius: 4px;
      opacity: 1;
      font-size: 12px;
      font-weight: 400;
      line-height: 30px;
      padding: 0 20px;
      color: #ffffff;
      cursor: pointer;
      &.on {
        background-color: #ffcf00;
        color: #434343;
      }
    }
  }
  &:after {
    clear: both;
    display: block;
    content: "";
  }
}

.TrueTopicItem {
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  border: 1px solid #c3c3c3;
  margin-top: 10px;
  .TrueTopicItemTitle {
    height: 50px;
    border-bottom: 1px solid #c3c3c3;
    padding: 0 15px;
    .TrueTopicItemTitleFl {
      float: left;
      margin-top: 10px;
      span,
      i,
      div {
        float: left;
        margin-right: 10px;
      }
      i {
        margin-top: 5px;
      }
      span {
        font-size: 20px;
        font-weight: bold;
        color: #173d7c;
      }
      div {
        height: 30px;
        background: #636363;
        line-height: 30px;
        border-radius: 16px;
        padding: 0 10px;
        i {
          margin-top: 3px;
        }
        span {
          color: #ffffff;
          font-size: 12px;
          font-weight: normal;
          margin: 0;
        }
      }
    }
    .TrueTopicItemTitleFr {
      float: right;
      font-size: 12px;
      font-weight: 400;
      color: #060606;
      line-height: 50px;
    }
  }
  .TrueTopicItemFont {
    padding: 20px 30px;
    .TrueTopicItemFontTitle {
      font-size: 16px;
      font-weight: 400;
      color: #060606;
      line-height: 28px;
    }
    .TrueTopicItemFontInfo {
      font-size: 16px;
      font-weight: 400;
      color: #060606;
      line-height: 28px;
      margin-top: 15px;
      &:after {
        clear: both;
        display: block;
        content: "";
      }
      video {
        float: left;
        width: 280px;
      }
      .TrueTopicItemFontpl {
        padding-left: 300px;
        .TrueTopicItemFontpl-title {
          font-size: 16px;
          font-weight: 400;
          color: #636363;
        }
        .TrueTopicItemFontpl-but {
          margin-top: 10px;
          button {
            border-radius: 10px;
            border: 0;
            color: #ffffff;
            height: 44px;
            background: #173d7c;
            border-radius: 8px 8px 8px 8px;
            opacity: 1;
            width: 120px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .TrueTopicItemp30 {
    padding: 15px 30px;
    border-top: 1px solid #c3c3c3;
    border-bottom: 1px solid #c3c3c3;
    line-height: 24px;
    div {
      font-size: 12px;
      font-weight: bold;
      color: #060606;
    }
    &:after {
      clear: both;
      display: block;
      content: "";
    }
  }
}
.fl {
  float: left;
}
.fr {
  float: right;
}
.purchaseShow {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  padding: 100px;
  .purchaseShowInfo {
    width: 900px;
    background: #ffffff;
    border-radius: 6px;
    border: 1px solid #aeaeae;
    padding: 30px;
    position: relative;
    margin: 0 auto;
    .cob {
      position: absolute;
      right: 20px;
      top: 20px;
      img {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
    .purchaseShow-title {
      font-size: 16px;
      font-weight: 300;
      color: #666666;
      span {
        font-weight: bold;
      }
    }
    .purchaseShow-ul {
      ul {
        margin-left: -20px;
        margin-top: 20px;
        li {
          float: left;
          padding-left: 18px;
          width: 25%;
          box-sizing: border-box;
          .p10px {
            padding: 10px;
            background: #ffffff;
            box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.16);
            border-radius: 6px;
            border: 1px solid #d1d1d1;
            text-align: center;
            .p10px-title {
              text-align: center;
              color: #666666;
              line-height: 30px;
              span {
                color: #173d7c;
                font-size: 20px;
              }
            }
            .p10px-font {
              font-size: 16px;
              font-weight: 400;
              color: #3f414a;
              text-align: center;
              line-height: 60px;
              span {
                font-weight: bold;
                font-size: 40px;
                color: #2e2e2e;
              }
            }
            .p10px-f30 {
              text-align: center;
              font-size: 16px;
              font-weight: 400;
              color: #666666;
              border-bottom: 1px solid #dddddd;
              line-height: 40px;
            }
            .but {
              height: 30px;
              background: #173d7c;
              border-radius: 16px;
              color: #ffffff;
              font-size: 16px;
              font-weight: 400;
              color: #ffffff;
              margin: 0 auto;
              margin-top: 15px;
              display: inline-block;
              line-height: 30px;
              padding: 0 30px;
            }
            &:hover,
            &.on {
              background: #ffcf00;
              box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.16);
              border-radius: 6px;
              border: 1px solid #d1d1d1;
              .p10px-f30 {
                border-bottom: 1px solid #7e7e7e;
              }
            }
          }
        }
      }
      &::after {
        display: block;
        clear: both;
        content: "";
      }
    }
    .purchaseShow-zf {
      margin-top: 30px;
      border: 1px solid #dddddd;
      .purchaseShow-zf-left {
        float: left;
        height: 190px;
        width: 190px;
        border-right: 1px solid #dddddd;
        background: #f2f2f2;
        div {
          margin-top: 12px;
          width: 168px;
          height: 52px;
          background: #ffcf00;
          border-radius: 0px 30px 30px 0px;
          text-align: center;
          line-height: 52px;
          font-weight: bold;
        }
      }
      .purchaseShow-zf-right {
        padding: 35px 35px 35px 225px;
        .purchaseShow-zf-right-col {
          float: left;
          width: 124px;
          height: 124px;
          background: #ffffff;
          border: 1px solid #707070;
          padding: 12px;
          img {
            width: 100px;
            height: 100px;
          }
        }
        .purchaseShow-zf-right-font {
          padding-left: 150px;
          .money {
            color: #173d7c;
            font-size: 16px;
            span {
              font-size: 48px;
              font-weight: bold;
            }
          }
          .money-bottom {
            font-size: 16px;
            line-height: 28px;
            color: #272727;
          }
        }
      }
      &::after {
        display: block;
        clear: both;
        content: "";
      }
    }
  }
}
.TitleReportItem1 {
  background: #fffcea;
  border-radius: 6px;
  border: 1px solid #b7a114;
  padding: 15px;
  i {
    float: left;
    width: 24px;
    height: 24px;
    display: block;
    background: url("~@/assets/TrueTopic/gdhao.png") no-repeat center;
    background-size: cover;
  }
  div {
    float: right;
    font-size: 16px;
    font-weight: 400;
    color: #636363;
    cursor: pointer;
    span {
      background: url("~@/assets/TrueTopic/down.png") no-repeat center;
      display: block;
      float: left;
      margin-right: 8px;
      background-size: cover;
      width: 16px;
      height: 16px;
      margin-top: 3px;
    }
    &.on {
      span {
        background: url("~@/assets/TrueTopic/up.png") no-repeat center;
        background-size: cover;
      }
    }
  }
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}
</style>
